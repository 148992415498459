/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #FF326F;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #df2059;
  --ion-color-primary-tint: #fd4d82;

  /** secondary **/
  --ion-color-secondary: #FF8243;
  --ion-color-secondary-rgb: 255, 130, 67;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e46c31;
  --ion-color-secondary-tint: #fa8f5a;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #beedb4;
  --ion-color-success-rgb: 190, 237, 180;
  --ion-color-success-contrast: #31871e;
  --ion-color-success-contrast-rgb: 49, 135, 30;
  --ion-color-success-shade: #a7d19e;
  --ion-color-success-tint: #c5efbc;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-grey: #999999;
  --ion-color-grey-rgb: 153, 153, 153;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #878787;
  --ion-color-grey-tint: #a3a3a3;

  --ion-color-light-grey: #ecedf1;
  --ion-color-light-grey-rgb: 236, 237, 241;
  --ion-color-light-grey-contrast: #000000;
  --ion-color-light-grey-contrast-rgb: 0, 0, 0;
  --ion-color-light-grey-shade: #d0d1d4;
  --ion-color-light-grey-tint: #eeeff2;

  --ion-color-light-secondary: #ffede1;
  --ion-color-light-secondary-rgb: 255, 237, 225;
  --ion-color-light-secondary-contrast: #FF8243;
  --ion-color-light-secondary-contrast-rgb: 255, 130, 67;
  --ion-color-light-secondary-shade: #e0d1c6;
  --ion-color-light-secondary-tint: #ffefe4;

  --ion-color-info: #e1edee;
  --ion-color-info-rgb: 225, 237, 238;
  --ion-color-info-contrast: #2c4f51;
  --ion-color-info-contrast-rgb: 44, 79, 81;
  --ion-color-info-shade: #c6d1d1;
  --ion-color-info-tint: #e4eff0;

  --ion-font-family: 'Poppins';
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-light-grey {
  --ion-color-base: var(--ion-color-light-grey);
  --ion-color-base-rgb: var(--ion-color-light-grey-rgb);
  --ion-color-contrast: var(--ion-color-light-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-grey-shade);
  --ion-color-tint: var(--ion-color-light-grey-tint);
}

.ion-color-light-secondary {
  --ion-color-base: var(--ion-color-light-secondary);
  --ion-color-base-rgb: var(--ion-color-light-secondary-rgb);
  --ion-color-contrast: var(--ion-color-light-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-secondary-shade);
  --ion-color-tint: var(--ion-color-light-secondary-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

/* @media (prefers-color-scheme: light) {

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }



  .ios body {
    --ion-background-color: #ffffff;
	  --ion-background-color-rgb: 255,255,255;

	  --ion-text-color: #34312D;
	  --ion-text-color-rgb: 52,49,45;

	  --ion-color-step-50: #f5f5f5;
	  --ion-color-step-100: #ebeaea;
	  --ion-color-step-150: #e1e0e0;
	  --ion-color-step-200: #d6d6d5;
	  --ion-color-step-250: #cccccb;
	  --ion-color-step-300: #c2c1c0;
	  --ion-color-step-350: #b8b7b6;
	  --ion-color-step-400: #aeadab;
	  --ion-color-step-450: #a4a2a1;
	  --ion-color-step-500: #9a9896;
	  --ion-color-step-550: #8f8e8c;
	  --ion-color-step-600: #858381;
	  --ion-color-step-650: #7b7977;
	  --ion-color-step-700: #716f6c;
	  --ion-color-step-750: #676562;
	  --ion-color-step-800: #5d5a57;
	  --ion-color-step-850: #52504d;
	  --ion-color-step-900: #484642;
	  --ion-color-step-950: #3e3b38;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }



  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */